import React, { Component } from 'react';
import Header from './Main/HeaderComponent'
import '../App.css'
import { Link } from 'react-router-dom';

class Home extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const home = this.props.projects.map(project => {
            return(
                <div 
                    key="project.id" 
                    className="homeContent"
                    style={{                         
                        backgroundImage: `url(${project.image})`,
                        backgroundPosition: "top center"
                    }}
                
                >
                    <br />
                    <br />
                    <Link to={project.url} >
                        <div className='homeText'>
                            <h2>{project.name}</h2>
                            <p>{project.description}</p>
                        </div>
                    </Link>
                    
                </div>
            )
        });

        return(
            <div>
                <Header /> 
                {home}
                {/* <div className='homeContent'
                    style={{ 
                        backgroundImage: `url(".../assets/images/home1.jpg")` 
                        //backgroundColor: '#1ba99b',
                        // width: 100%
                    }}
                >
                    <h2>Living with My Stubborn Huskies</h2>
                    <p>Adventures are waiting for us.</p>
                    <img src={require('../images/cover1.jpg')}/>

                </div> */}
            </div>
            
        )
    }
}

export default Home;