import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import Directory from './DirectoryComponent';
import Header from './Main/HeaderComponent';
import Footer from './Main/FooterComponent';
import Home from './HomeComponent';
import Contact from './ContactComponent';
import About from './AboutComponent';
import Portfolio from './PortfolioComponent';
import { Huskies } from './HuskiesComponent';
import { PROJECTS } from '../shared/projects'
import { PORTFOLIO_PROJECTS } from '../shared/portfolio_projects';
// import { BlogHusky1 } from './Blog/BlogHusky1';
import { Baby } from './Baby';
import { Switch, Route, Redirect } from 'react-router-dom';
// import 'App.css'


class Main extends Component {
    constructor(props){
        super(props);
        this.state = {
            projects: PROJECTS,
            portfolio_projects: PORTFOLIO_PROJECTS
        };
    }

    render(){

        const HomePage = () => {
            return (
                <Home projects={this.state.projects}/>
            );
        }
        const PortfolioPage = () => {
            return (
                <Portfolio projects={this.state.portfolio_projects} />
            )
        }

        return(
            <div>
                {/* <Header />   */}
                <Switch>
                    <Route  path='/home' component={HomePage} />
                    <Route exact path='/directory' render={() => <Directory projects={this.state.projects} />} />
                    <Route exact path='/contact' component={Contact} />
                    <Route exact path='/about' component={About} />
                    {/* <Route exact path='/portfolio' component={PortfolioPage}/> */}
                    <Route exact path="/">
                        <Redirect to="/home" />
                    </Route>
                    <Route exact path="/portfolio" 
                        component={() => { 
                            window.location.href = 'https://confident-goldberg-6b69ee.netlify.app/' 
                            return null;
                        }}
                    />
                    <Route exact path="/huskies" component={Huskies}/>
                    {/* <Route exact path="/huskies/blog1" component={BlogHusky1} /> */}
                    <Route exact path="/projectbaby" component={Baby} />
                </Switch>
                
                
                <Footer />
            </div>
        );
    };
}

export default Main;