import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import '../App.css'

class Portfolio extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const portfolio_projects = this.props.projects.map(project => {
            return(

                <div key="project.id" style={{ width: "300px", margin: 20 }} className="col">
                    <Image variant="top" src={project.image} className="portfolioImage"/>
                    <h2> {project.name} </h2>
                    <p> {project.description} </p>
                    <Button className="portfolioButton">Check It Out</Button>
                </div>
            )
        });

        return (
            <div>

                <div className="container"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        flexWrap: "wrap"
                      }}
                >
                    
                    {portfolio_projects}
                    
                </div>

            </div>
        );
    };
}

export default Portfolio;