import React , {Component} from 'react';
import Sectiontitle from './Sectiontitle';
import BlogItems from './BlogItems';
import Reveal from 'react-reveal/Reveal/';


class Blog extends Component{
    render(){
        return(
            <div>               
                <section className="blog_area" id="blog">
                    <div className="container">
                        <Sectiontitle Title="Our Blog" TitleP="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."/>
                        <Reveal effect="fadeInUp" duration={1000}>
                            <div className="row">
                                    <BlogItems bTitle="Dog Friendly Activities - South Bay I" bDetails="Places to eat and play, and dog friendly lodging." btnText="Read More" image="post-img1.jpg" Pdata=" 2/20 2022" />
                                    <BlogItems bTitle="Dog Friendly Activities - South Bay II" bDetails="Suspendisse in mattis neque, sed accu- msan erat. Maecenas eget metus dui. Vestibulum accumsan massa quam.." btnText="Read More" image="post-img2.jpg" Pdata="2/22 2022"/>
                                    <BlogItems bTitle="Post with sidebar" bDetails="Suspendisse in mattis neque, sed accu- msan erat. Maecenas eget metus dui. Vestibulum accumsan massa quam.." btnText="Read More" image="post-img3.jpg" Pdata="Apr 04"/>
                            </div>
                        </Reveal>
                    </div>
                </section>
            </div>
        )
    }
}
export default Blog;