import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarBaby from "./Nav/NavbarBaby";
import Carousel from "react-bootstrap/Carousel";



export const Baby = () => (
    <div>                
        <div className="babyCover">     
        </div>
        <NavbarBaby />

        <div className="container">
            <h1 className="margin30">Welcome Home</h1>
            <Carousel className="col col-6 center">
                <Carousel.Item className="sizeMedium">
                    <img
                    // className="d-block"
                    src={require("../images/baby1.jpg").default}
                    alt="Nursery Photo1"
                    />
                    <Carousel.Caption>
                    <h3>We painted the wall blue</h3>
                    <p>Sophia picked the color - we got a bunch of color cards, and Sophia chewed that particular color.🥰</p>
                    
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="sizeMedium">
                    <img
                    // className="d-block"
                    src={require("../images/baby2.jpg").default}
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h3>The theme is the safari</h3>
                    <p>We hope you like the giraffe, the lion, the zebra, the elephant, and the tiger🐯!</p>
                    
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="sizeMedium">
                    <img
                    // className="d-block imageSizeMedium"
                    src={require("../images/baby3.jpg").default}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>The rocking chair</h3>
                    <p>We hope to read all the wonderful stories to you here. Dream big, little guy.</p>
                    
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </div>

        <div className="container">
            <div className="margin30">
                <h1>Feeding Guide</h1>
                <table>
                    <tr>
                        <td className="col col-2">First 24 Hours</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Day 2</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Day 3</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Day 4</td>
                        <td className="col col-2"></td>
                    </tr>
                    <tr>
                        <td className="col col-2">Every 2 hours</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Every 2 hours</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Day 3</td>
                        <td className="col col-2"></td>
                        <td className="col col-2">Day 4</td>
                        <td className="col col-2"></td>
                    </tr>
                </table>

            </div>
        </div>


    </div>
)