import React, { Component } from "react";
import Blog from './Blog/Blog'
import "bootstrap/dist/css/bootstrap.min.css";
import '../App.css'
import NavHuskies from "./Nav/NavbarHuskies";


export const Huskies = () => (
    <div>                
        <div className="huskyCover">
            <div className="homeText">
                <h1>All About The Huskies</h1>
                <p>Exploring the World with Stella &amp; Sophia</p>
            </div>
        
        </div>
        <NavHuskies />

        
        <Blog />
    </div>
)
