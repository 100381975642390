export const PORTFOLIO_PROJECTS = [
    {
        id: 0,
        type: 'Python',
        name: 'Snake Game',
        image: '/assets/images/snake_game.png',
        description: 'Blog, Travel, Love'
    },
    {
        id: 1,
        type: 'Python',
        name: 'Pong Game',
        image: '/assets/images/pong_game.png',
        description: 'Blog, Travel, Love'
    },
    {
        id: 2,
        type: 'Python',
        name: 'Pomodoro',
        image: '/assets/images/pomodoro.gif',
        description: 'Blog, Travel, Love'
    },
    {
        id: 3,
        type: 'Python',
        name: 'Flash Card',
        image: '/assets/images/flashy.gif',
        description: 'Blog, Travel, Love'
    },
    {
        id: 4,
        type: 'Python',
        name: 'Name the States',
        image: '/assets/images/name_the_state.png',
        description: 'Blog, Travel, Love'
    },
    {
        id: 5,
        type: 'Python',
        name: 'NATO Alphabet',
        image: '/assets/images/nato.png',
        description: 'Blog, Travel, Love'
    },
    {
        id: 6,
        type: 'Python',
        name: 'Pomodoro',
        image: '/assets/images/pomodoro.gif',
        description: 'Blog, Travel, Love'
    },
    {
        id: 7,
        type: 'Python',
        name: 'Pomodoro',
        image: '/assets/images/pomodoro.gif',
        description: 'Blog, Travel, Love'
    },
    {
        id: 8,
        type: 'Python',
        name: 'Pomodoro',
        image: '/assets/images/pomodoro.gif',
        description: 'Blog, Travel, Love'
    },
    {
        id: 9,
        type: 'Python',
        name: 'Pomodoro',
        image: '/assets/images/pomodoro.gif',
        description: 'Blog, Travel, Love'
    }

    
]