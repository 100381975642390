export const PROJECTS = [
    {
        id: 0,
        name: 'Living With My Stubborn Huskies',
        image: '/assets/images/home1.jpg',
        description: 'Blog, Travel, Love',
        url:'/huskies'
    },
    // {
    //     id: 1,
    //     name: 'The HomeMaker\'s Tool',
    //     image: '/assets/images/story2.jpeg',
    //     description: 'A handy tool for home management - asset management, budget management, weekly menu, shopping list, and reminders'
    // },
    {
        id: 2,
        name: 'Project Baby',
        image: '/assets/images/home3.jpg',
        description: 'Starting my new journey with my baby',
        url:'/projectbaby'
    },
    {
        id: 3,
        name: 'Projects Portfolio',
        image: '/assets/images/home4.jpg',
        description: 'Python Projects Demos and Designed Web Applications',
        url: '/portfolio'
    }

];